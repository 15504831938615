//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import * as am4plugins_sunburst from '@amcharts/amcharts4/plugins/sunburst';

import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { mapGetters, mapActions } from 'vuex';

import eventBus from '@/plugins/eventBus';

import * as _ from 'underscore';

import XLSX from 'xlsx'

am4core.useTheme(am4themes_animated);

export default {
  name: 'topics',
  data() {
    return {
      showChart: true,
      showInnerLoading: false,
      showInnerLoadingTree: false,
      Sunburst_chart_data: [],
      Sunburst_chart_detial_data: [],
      parametersOnly: false,
      isCall: false,
      columns: [],
      table_data: [],
      showTable: false,
      showTableLoading: false
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: "getDarkTheme"
    }),
  },
  methods: {
    ...mapActions({
      requestConversationsFlow2All: 'requestConversationsFlow2All',
      requestConversationsTopicParameters: 'requestConversationsTopicParameters',
      generateXMLFile: 'generateXMLFile',
    }),
    generateSunburstChartParametersOnly() {
      const that = this;
      if(this.sunburstChartParametersOnly){
        this.sunburstChartParametersOnly.dispose()
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark);
      }
      // create chart
      const chart = am4core.create(
        that.$refs.sunburstChartParametersOnly,
        am4plugins_sunburst.Sunburst,
      );
      chart.padding(0, 0, 0, 0);
      chart.radius = am4core.percent(98);
      chart.data = that.Sunburst_chart_data;
      // define data fields
      chart.colors.step = 2;
      chart.fontSize = 11;
      chart.innerRadius = am4core.percent(20);

      // define data fields
      chart.dataFields.value = 'value';
      chart.dataFields.name = 'name';
      chart.dataFields.children = 'children';

      const level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
      level0SeriesTemplate.hiddenInLegend = false;
      chart.seriesTemplates.setKey('0', level0SeriesTemplate);

      // this makes labels to be hidden if they don't fit
      level0SeriesTemplate.labels.template.truncate = true;
      level0SeriesTemplate.labels.template.hideOversized = true;

      level0SeriesTemplate.labels.template.adapter.add('rotation', (
        rotation,
        target,
      ) => {
        target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
        target.maxHeight = Math.abs(
          ((target.dataItem.slice.arc
            * (target.dataItem.slice.innerRadius
              + target.dataItem.slice.radius))
            / 2)
            * am4core.math.RADIANS,
        );
        return rotation;
      });

      const level1SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey('1', level1SeriesTemplate);
      level1SeriesTemplate.fillOpacity = 0.75;
      level1SeriesTemplate.hiddenInLegend = true;

      const level2SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey('2', level2SeriesTemplate);
      level2SeriesTemplate.fillOpacity = 0.5;
      level2SeriesTemplate.hiddenInLegend = true;

      chart.legend = new am4charts.Legend();
      chart.legend.maxWidth = 400;
      chart.legend.position = 'right';
      chart.legend.scrollable = true;
      chart.legend.valueLabels.template.align = 'right';
      chart.legend.valueLabels.template.textAlign = 'start';

      that.sunburstChartParametersOnly = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Parameters Overview-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
    },
    generateSunburstChartDetail() {
      const that = this;
       if(this.sunburstChartDetail){
        this.sunburstChartDetail.dispose()
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark);
      }
      // create chart
      const chart = am4core.create(
        that.$refs.sunburstChartDetial, am4plugins_sunburst.Sunburst,
      );

      chart.padding(0, 0, 0, 0);
      chart.radius = am4core.percent(98);
      chart.data = that.Sunburst_chart_detial_data;
      chart.colors.step = 2;
      chart.fontSize = 11;
      chart.innerRadius = am4core.percent(10);

      // define data fields
      chart.dataFields.value = 'value';
      chart.dataFields.name = 'name';
      chart.dataFields.children = 'children';

      const level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
      level0SeriesTemplate.hiddenInLegend = false;
      chart.seriesTemplates.setKey('0', level0SeriesTemplate);

      // this makes labels to be hidden if they don't fit
      level0SeriesTemplate.labels.template.truncate = true;
      level0SeriesTemplate.labels.template.hideOversized = true;

      level0SeriesTemplate.labels.template.adapter.add('rotation', (
        rotation,
        target,
      ) => {
        target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
        target.maxHeight = Math.abs(
          ((target.dataItem.slice.arc
            * (target.dataItem.slice.innerRadius
              + target.dataItem.slice.radius))
            / 2)
            * am4core.math.RADIANS,
        );
        return rotation;
      });

      const level1SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey('1', level1SeriesTemplate);
      level1SeriesTemplate.fillOpacity = 0.75;
      level1SeriesTemplate.hiddenInLegend = true;

      const level2SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey('2', level2SeriesTemplate);
      level2SeriesTemplate.fillOpacity = 0.5;
      level2SeriesTemplate.hiddenInLegend = true;

      chart.legend = new am4charts.Legend();
      chart.legend.maxWidth = 400;
      chart.legend.position = 'right';
      chart.legend.scrollable = true;
      chart.legend.valueLabels.template.align = 'right';
      chart.legend.valueLabels.template.textAlign = 'start';

      that.sunburstChartDetail = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Parameters Overview-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
    },
    exportXls(){
      let fileName = `EVO_Dynamics_report${this.currentAIAccount.name}_${this.getFilterDate.start}_to_${this.getFilterDate.end}_message_report_parameters_overview`
      var data = _.map(this.table_data , (record , index) => {
          return {
            'S. No':  index + 1,
            ...record
          }
        });
      /* this line is only needed if you are not adding a script tag reference */
      /* make the worksheet */
      var ws = XLSX.utils.json_to_sheet(data);
      // /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "People");
      // /* generate an XLSX file */
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: 'onDateUpdate',
        };
        that.isCall = false;
        that.updateParametersOverview(data);
      }
    },
    updateParametersOverview(data) {
      const that = this;
      if (that.sunburstChartParametersOnly) {
        that.sunburstChartParametersOnly.dispose();
      }
      if (that.sunburstChartDetail) {
        that.sunburstChartDetail.dispose();
      }
      if (that.parametersOnly == true) {
        that.updateSunburstChartParametersOnly(data);
      } else {
        that.updateSunburstChartDetial(data);
      }
    },
    updateSunburstChartParametersOnly(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.showTableLoading = true
        that.isCall = true;
        that.requestConversationsFlow2All(data).then((res) => {
          this.generateParamatersTable(res.parameter_data)
          const groupByGroup = _.groupBy(res.mappedData, 'group');
          const grouped_array = [];
          for (const key in groupByGroup) {
            grouped_array.push({
              name: key,
              children: groupByGroup[key],
            });
          }
          that.Sunburst_chart_data = grouped_array;
          that.showInnerLoading = false;
          that.showTableLoading = false
          that.currentPage = 0;
          setTimeout(() => {
            that.generateSunburstChartParametersOnly();
          }, 100);
        }).catch((err) => {
          that.showChart = false;
          that.showInnerLoading = false;
          that.showTableLoading = false
        }).finally(() => {
          setTimeout(() => {
            that.isCall = false;
          }, 500);
        });
      }
    },
    updateSunburstChartDetial(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.showTableLoading = true
        that.showPagination = false;
        that.isCall = true;
        that.requestConversationsTopicParameters(data).then((res) => {
          this.generateParamatersTable(res.parameter_data)
          that.Sunburst_chart_detial_data = res.result;
          that.showInnerLoading = false;
          that.showTableLoading = false
          setTimeout(() => {
            that.generateSunburstChartDetail();
          }, 100);
        }).catch((err) => {
          that.showChart = false;
          that.showInnerLoading = false;
          that.showTableLoading = false
        }).finally(() => {
          setTimeout(() => {
            that.isCall = false;
          }, 500);
        });
      }
    },
    generateParamatersTable(parameter_data){
      if(parameter_data.length > 0){
        this.showTable = true
        this.showTableLoading = true
        let columns_keys = _.keys(parameter_data[0])
        let columns = []
        _.forEach(columns_keys , column =>{
          let align = 'left'
          if(column === 'Value'){
            align = 'center'
          }
          columns.push({
            name: column,
            required: true,
            label: column,
            align:align ,
            field: row => row[column],
            sortable: true
          })
        })
        this.columns = columns
        this.table_data = parameter_data
        this.showTableLoading = false
      }
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updateParametersOverview(data);
    },
    isValid(value) {
      if (
        value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    parametersOnly(newVal) {
      const that = this;
      const data = {
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'watch',
      };
      this.showTable = false
      that.updateParametersOverview(data);
    },
    darkTheme(){
      if(this.parametersOnly === true){
        this.generateSunburstChartParametersOnly();
      }else{
        this.generateSunburstChartDetail()
      }
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    that.updateParametersOverview(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
